import React from "react"

const defaultState = {
  drawer: false,
  toggleDrawer: () => {},
}

const ThemeContext = React.createContext(defaultState)

class ThemeProvider extends React.Component {
  state = {
    drawer: false,
  }

  toggleDrawer = () => {
    let drawer = !this.state.drawer
    localStorage.setItem("drawer", JSON.stringify(drawer))
    this.setState({ drawer })
  }

  componentDidMount() {
    const lsDrawer = JSON.parse(localStorage.getItem("drawer"))
    if (lsDrawer) {
      this.setState({ drawer: lsDrawer })
    }
  }

  render() {
    const { children } = this.props
    const { drawer } = this.state
    return (
      <ThemeContext.Provider
        value={{
          drawer,
          toggleDrawer: this.toggleDrawer,
        }}
      >
        {children}
      </ThemeContext.Provider>
    )
  }
}

export default ThemeContext

export { ThemeProvider }
